export const Language = {
    default: 'en',
    GetLanguage: function () {
        let ps_language = localStorage.getItem('ps_language');
    
        if (ps_language) {
            return ps_language;
        } else {
            return 'en';
        }
    },
    SetLanguage: function (lang) {
        localStorage.setItem('ps_language', lang);
    }
};
