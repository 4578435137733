<template>
  <div class="schedule-wrapper__schedule --holiday-overview">
    <div class="schedule-overview">
      <ul class="calendar__controlls">
        <a class="prev" @click.prevent="DateController(false)" href=""><span>&#8249;</span></a>
        <p>{{ GetTextMonth }}<span v-if="month !== new Date().getMonth()"> - <a id="GoToNow" @click.prevent="GoToCurrentMonth" href="">{{ $t('Now') }}</a></span></p>
        <a class="next" @click.prevent="DateController(true)" href=""><span>&#8250;</span></a>
      </ul>
      <div class="schedule-overview__calendar" @scroll="StickyEmployee">
        <ul class="calendar-header">
          <li class="employees-tab">{{ $t('Employees') }}</li>
          <li class="dates-tab" :class="{off: [0, 6].includes(date.getDay()), today: IsToday(date)}" :key="index" v-for="(date, index) in GetMonth()" :title="$t('Week') + ' ' + WeekNumber(date)">
            <span class="day">{{ $t(GetDayLetter(date.getDay()).toLowerCase()).toUpperCase() }}</span>
            <span class="date">{{ date.getDate() }}</span>
          </li>
        </ul>
        <ul class="calendar-body --employee" :key="index" v-for="(employee, index) in employees">
          <li class="employees-tab">
            <span class="sticky" :title="TitleCase(employee.name + ' ' + employee.surname)">
              <p>{{ TitleCase(employee.name + ' ' + employee.surname) }}</p>
            </span>
          </li>
          <li class="dates-tab" :class="{off: [0, 6].includes(date.getDay()), today: IsToday(date)}" :key="index" v-for="(date, index) in GetMonth()">
            <div @click.prevent="ShowEvent" v-html="GetEvent(date, employee)" />
          </li>
        </ul>
      </div>
    </div>
    <ul class="event-reasons">
      <li :key="index" v-for="(item, index) in schema">
        <span :class="'schedule-reason-' + index"></span>
        {{ item }}
      </li>
    </ul>

    <Modal modal="edit_leave" :value="modal.edit_leave.open" :title="$t('Edit ' + (!SelectedDate.approve ? 'request' : 'leave'))">
      <div id="edit-leave">
        <form @submit.prevent="ApproveLeave" class="form" action="#" autocomplete="off">
          <div class="form-group" id="user">
            <Select name="name" mode="single" readonly="true" :list="user.options" :selected="[user.selected]" />
            <Select name="reason" mode="single" :list="schema" :selected="[SelectedDate.reason_id]" style="margin-left: 30px;" />
            <DatePicker name="start date" @date="GetDate" :selected="SelectedDate.from_date" />
            <DatePicker name="end date" :DisabledBefore="DisabledBefore" :selected="SelectedDate.to_date" style="margin-left: 30px;" />
            <div style="display: flex; width: 100%; padding-left: 20px;">
              <label class="checkbox" for="half-day" style="width: auto; cursor: pointer;">
                <span class="span">{{ $t('Is a half day?') }}</span>
                <input type="checkbox" id="half-day" name="half-day" :checked="SelectedDate.half_day">
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
              </label>
            </div>
            <label class="full" :class="SelectedDate.note !== '<br>' ? 'filled' : ''" for="notes">
              <span :class="SelectedDate.note !== '<br>' ? 'show' : ''" class="span">{{ $t('Note') }}</span>
              <textarea rows="1" name="notes" id="notes" @input="ValidateInput" :placeholder="$t('Note')" v-html="SelectedDate.note !== '<br>' ? SelectedDate.note : ''"></textarea>
            </label>
          </div>
          <div class="form-action">
            <input v-if="!SelectedDate.approve" class="submit done" style="min-width: 115px; margin-right: auto;" type="submit" :value="$t('Approve')">
            <input class="submit done" style="min-width: 115px; margin-right: 20px;" type="submit" @click.prevent="SaveLeave" :value="$t('Save')">
            <input class="submit danger" style="min-width: 115px;" type="submit" @click.prevent="RejectLeave" :value="$t('Delete')">
          </div>
        </form>
      </div>
    </Modal>

    <Messages />
    <ValidatePopup />

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Permissions }            from '@/helpers/Permissions';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';
  import { Dates }                  from '@/mixins/Dates';
  import DatePicker                 from '@/components/snippets/DatePicker';
  import Select                     from '@/components/snippets/Select';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'ScheduleHolidayOverview',
    mixins: [
      FormElementsBehaviour, 
      TableElementsBehaviour, 
      Permissions, 
      BPA, 
      Tool, 
      Dates
    ],
    components: {
      DatePicker,
      Select,
      Modal
    },
    data() {
      return {
        DisabledBefore: null,
        SelectedDate: {},
        month: null,
        year: null,
        user: {
          selected: 0,
          options: {}
        },
        employees: [],
        events: [],
        schema: {},
        modal: {
          edit_leave: {
            open: false
          }
        },
        AdminRoles: [/*0, 15*/ 'admin', 'schedule_admin']
      }
    },
    async created() {
      let date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth();
      this.GetEvents();
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
      });
    },
    computed: {
      GetTextMonth() {
        return this.$t(Dates.GetTextMonth(this.month)) + ' ' + this.year;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        this.GetSchema();
        this.TranslateEvent();
      }
    },
    methods: {
      WeekNumber(date = '') {
        return Tool.WeekNumber(date);
      },
      TitleCase(string, split) {
        return Tool.TitleCase(string, split);
      },
      IsToday(date) {
        const today = new Date();
        return date.getDate() == today.getDate() 
        && date.getMonth() == today.getMonth() 
        && date.getFullYear() == today.getFullYear();
      },
      async UpdateLeave(request) {
        await BPA.api.UpdateLeave(request).then(response => {
          return BPA.api.response({response});
        }).then(response => {
          if (!response.ok) return;
          this.GetEvents();
          this.$eventHub.$emit('ShowMessages', {
            message: 'Leave updated',
            type: 'success',
            hide: 2000
          });
        }).catch(e => e);
      },
      async GetEvents() {
        await BPA.api.GetCompanyLeave([this.year, this.month + 1]).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let employees = response.result || [];
          // Remove all duplicates from array of employees
          employees = employees.filter((item, index, self) => index == self.findIndex(e => e.id == item.id));
          // Add full_name property for list sorting
          employees = employees.map(employee => ({...employee, ...{full_name: employee.name + ' ' + employee.surname}}));
          // Sort list alphabetically by full_name property
          this.employees = Tool.Alphabetize(employees, 'full_name');
          this.GetSchema();
          this.$nextTick().then(() => {
            this.TranslateEvent();
          });
        }).catch(e => e);
      },
      TranslateEvent() {
        document.querySelectorAll('.dates-tab [data-date]').forEach(element => {
          const data = JSON.parse(element.dataset.date);
          element.title = this.schema[data.reason_id] + ' (' + this.$t((!data.approve ? 'un' : '') + 'approved') + ')';
        });
      },
      GetMonth() {
        let dates = [],
          first_date = new Date(this.year, this.month, 1),
          last_date = new Date(this.year, this.month + 1, 0);
        for (let i = first_date.getDate(); i <= last_date.getDate(); i++) {
          dates.push(new Date(this.year, this.month, i));
        }
        return dates;
      },
      GetSchema() {
        let schema = BPA.api.ScheduleReasons('GET');
        for (let i in schema) schema[i] = this.$t(schema[i]);
        this.schema = schema;
      },
      GetEvent(date, employee) {
        if (date == '') return;
        let timestamp = date.setHours(1, 0, 0),
          html = document.createElement('span'),
          color_flag = false,
          half_flag = false,
          first_flag = false,
          last_flag = false,
          reason = null,
          data = null,
          approved_flag = false;
        const employee_events = employee.id == BPA.session.auth().user.user_id;

        employee.items.map(event => {
          if (timestamp >= new Date(event.from_date).setHours(0, 0, 0) && timestamp <= new Date(event.to_date).setHours(23, 59, 59)) {
            color_flag = true;
            reason = event.reason_id;

            if (event.half_day) {
              half_flag = true;
            }
            
            if (event.approve) {
              approved_flag = true;
            }

            if (this.Check([/*0, 15*/ 'admin', 'schedule_admin'])) {
              data = event;
            }
          }
          if (date.getDate() == new Date(event.from_date).getDate() &&
            date.getMonth() == new Date(event.from_date).getMonth() &&
            date.getFullYear() == new Date(event.from_date).getFullYear()) {
            first_flag = true;
          }
          if (date.getDate() == new Date(event.to_date).getDate() &&
            date.getMonth() == new Date(event.to_date).getMonth() &&
            date.getFullYear() == new Date(event.to_date).getFullYear()) {
            last_flag = true;
          }
        })

        if (color_flag) {
          html.classList.add('color');
          html.classList.add('schedule-reason-' + reason);
        }

        if (half_flag) {
          html.classList.add('half');
        }
        
        if (first_flag) {
          html.classList.add('first');
        }
        
        if (last_flag) {
          html.classList.add('last');
        }
        
        if (employee_events || this.Check([/*0, 15*/ 'admin', 'schedule_admin'])) {
          if (approved_flag) {
            html.classList.add('approved');
          } else {
            html.classList.add('unapproved');
          }
        }
        
        if (!this.Check([/*0, 15*/ 'admin', 'schedule_admin'])) {
          html.classList.add('read-only');
        }

        if (reason !== null) {
          if (Object.keys(this.schema).includes(reason.toString())) {
            let title = this.GetLabel(reason);
            if (approved_flag) {
              title += ' (approved)';
            } else {
              title += ' (unapproved)';
            }
            html.title = title;
          }
        }

        if (data) {
          html.dataset.date = JSON.stringify(data);
        }

        return html.outerHTML;
      },
      StickyEmployee(event) {
        let tabs = event.target.querySelectorAll('.employees-tab');
        if (event.target.scrollLeft > 0) {
          tabs.forEach(x => {
            let el = x.querySelector('.sticky');
            if (el) {
              el.classList.add('float');
              el.style.left = event.target.scrollLeft - 1 + 'px';
            }
          })
        } else if (event.target.scrollLeft < 1) {
          tabs.forEach(x => {
            let el = x.querySelector('.sticky');
            if (el) {
              el.classList.remove('float');
              el.style.left = 0 + 'px';
            }
          })
        }
      },
      DateController(action) {
        if (action) {
          if (this.month == 11) {
            this.year++;
            this.month = 0;
          } else {
            this.month++;
          }
        } else {
          if (this.month == 0) {
            this.year--;
            this.month = 11;
          } else {
            this.month--;
          }
        }
        this.GetEvents();
      },
      GoToCurrentMonth() {
        let date = new Date();
        this.month = date.getMonth();
        this.year = date.getFullYear();
        this.GetEvents();
      },
      GetLabel: function (id) {
        return BPA.api.ScheduleReasons('GET')[id];
      },
      ShowEvent(event) {
        if (!this.Check([/*0, 15*/ 'admin', 'schedule_admin'])) return;
        let el = event.currentTarget.firstChild;
        if (Object.values(el.dataset).length) {
          const data = JSON.parse(el.dataset.date);
          const date = new Date(data?.from_date);
          date.setHours(0, 0, 0);
          this.DisabledBefore = date;
          this.SelectedDate = data;
          this.user.options[0] = event?.target?.closest('.--employee')?.querySelector('[title]')?.title;
          this.user.selected = 0;
          this.modal.edit_leave.open = true;
        }
      },
      async ApproveLeave(event) {
        let state = this.ValidateForm(event.target);
        state.leave.approve = true;
        if (state.flag && Object.keys(state.leave).length > 0) {
          await this.UpdateLeave({leave_id: this.SelectedDate.id, leave_data: state.leave});
        }
      },
      async RejectLeave(event) {
        let leave_id;
        if (event.target.closest('tr')) {
          leave_id = JSON.parse(event.target.closest('tr').dataset.leave).id;
        } else {
          leave_id = this.SelectedDate.id;
        }
        await this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, delete leave',
          disapprove: 'No',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          BPA.api.RejectLeave(leave_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.GetEvents();
            this.modal.edit_leave.open = false;
            this.$eventHub.$emit('ShowMessages', {
              message: 'Leave deleted',
              type: 'success'
            });
          }).catch(e => e);
        });
      },
      async SaveLeave(event) {
        let state = this.ValidateForm(event.target.closest('form'));
        if (state.flag && Object.keys(state.leave).length > 0) {
          await this.UpdateLeave({leave_id: this.SelectedDate.id, leave_data: state.leave});
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
        }
      },
      ValidateForm(form) {
        let updated = {}, flag = true;
        let StartDateInput = form.querySelector('input[name="start date"]');
        if (StartDateInput.value !== '') {
          let date = new Date(parseInt(StartDateInput.value));
          date = date.getFullYear() + '-' + Dates.FormatDoubleDigits(date.getMonth() + 1) + '-' + Dates.FormatDoubleDigits(date.getDate());
          if (date !== this.SelectedDate.from_date) {
            updated.from_date = date;
          }
        } else {
          this.Error(
            StartDateInput,
            'Start date must be set'
          );
          flag = false;
        }

        let EndDateInput = form.querySelector('input[name="end date"]');
        if (EndDateInput.value !== '') {
          let date = new Date(parseInt(EndDateInput.value));
          date = date.getFullYear() + '-' + Dates.FormatDoubleDigits(date.getMonth() + 1) + '-' + Dates.FormatDoubleDigits(date.getDate());
          if (date !== this.SelectedDate.to_date) {
            updated.to_date = date;
          }
        } else {
          this.Error(
            EndDateInput,
            'End date must be set'
          );
          flag = false;
        }

        let ReasonInput = form.querySelector('input[name="reason"]');
        if (ReasonInput.value !== '') {
          if (parseInt(ReasonInput.value) !== this.SelectedDate.reason_id) {
            updated.reason_id = parseInt(ReasonInput.value);
          }
        } else {
          this.Error(
            ReasonInput,
            'Reason must be set'
          );
          flag = false;
        }

        /*
        if (this.IsSuperUser()) {
          let NameInput = form.querySelector('input[name="name"]');
          NameInput.value = NameInput.value.replace('#', '');
          if (NameInput.value !== '') {
            leave.user_id = parseInt(NameInput.value);
          } else {
            delete leave.user_id;
          }
        }
        */

        let HalfDayInput = form.querySelector('input[name="half-day"]');
        if (HalfDayInput.checked !== this.SelectedDate.half_day) {
          updated.half_day = HalfDayInput.checked;
        }
        
        let NotesInput = form.querySelector('textarea[name="notes"]');
        if (NotesInput.value !== '') {
          updated.note = NotesInput.value;
        }

        return {
          'flag': flag,
          'leave': updated
        }
      },
      GetDate(date) {
        this.DisabledBefore = date;
      },
      GetDayLetter(day) {
        return Dates.DayLetter(day);
      },
      Check(required) {
        return BPA.permissions(required).length;
      }
    }
  }
</script>

<style scoped>
</style>
