<template>
  <label :for="name" class="date-select-label" :class="selected ? 'filled' : ''">
    <span class="span" :class="{show: selected}">{{ Label() }}</span>
    <input type="hidden" :name="name" :value="GetDateTime" />
    <input :name="name + '-fake'" type="text" class="fake-date" @change="Changed" :placeholder="Label()" @keydown.esc="HideDropdown" @keydown.tab="HideDropdown" @keydown.delete="ResetValue($event.target)" @input="ValidateInput" @keypress="PreventInput" :value="GetTextDate" @focus="ShowDropdown" @click="ShowDropdown">
    <div class="date-select-dropdown">
      <div class="calendar__controlls">
        <a class="prev" @click.prevent="DateController(false)" href=""><span>&#8249;</span></a>
        <p>{{ GetTextMonth }}</p>
        <a class="next" @click.prevent="DateController(true)" href=""><span>&#8250;</span></a>
      </div>
      <ul class="calendar__header">
        <li :key="day" v-for="day in CalendarHeader()"> {{ $t(day.toLowerCase()) }} </li>
      </ul>
      <ul class="calendar__body">
        <li :key="String(date)" v-for="date in CalendarBody()" :class="[{today: IsToday(date), disabled: [0, 6].includes(date.getDay())}, CheckAvailability(date)]">
          <a v-if="date !== ''" @click.prevent="SetDate($event, date)" :class="['date', {active: SelectedDate ? DateString(date) == DateString(SelectedDate) : null}]"  href="">
            <span>{{ date.getDate() }}</span>
          </a>
        </li>
      </ul>
    </div>
  </label>
</template>

<script>
  import { FormElementsBehaviour } from '@/mixins/FormElementsBehaviour';
  import { Dates }                 from '@/mixins/Dates';
  import { Tool }                  from '@/helpers/Tool';

  export default {
    name: 'Date',
    mixins: [FormElementsBehaviour],
    data() {
      return {
        year: null,
        month: null,
        binding: null,
        dropdown: null,
        SelectedDate: null,
        IsBound: null
      }
    },
    props: {
      name: {
        required: true
      },
      label: {
        required: false
      },
      selected: {
        required: false
      },
      DisabledBefore: {
        required: false
      }
    },
    computed: {
      GetTextDate() {
        if (this.SelectedDate !== null) {
          let text_date = Dates.GetTextDate(this.SelectedDate).split(' ');
          text_date[1] = this.$t(text_date[1]);
          return text_date.join(' ');
        }
        return '';
      },
      GetDateTime() {
        if (this.SelectedDate !== null) {
          return this.SelectedDate.getTime();
        }
        return '';
      },
      GetTextMonth() {
        return this.$t(Dates.GetTextMonth(this.month)) + ' ' + this.year;
      }
    },
    watch: {
      DisabledBefore(change) {
        let date = new Date();
        if (change) {
          date = new Date(change);
        }
        if (new Date(this.DisabledBefore) > this.SelectedDate) {
          this.ResetValue(this.dropdown);
        }
        this.year = date.getFullYear();
        this.month = date.getMonth();
      }
    },
    mounted() {
      let date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth();
      if (this.selected) {
        this.SelectedDate = new Date(this.selected);
      }
    },
    methods: {
      Label() {
        return this.$t(Tool.Capitalize(this.label || this.name));
      },
      CalendarHeader() {
        const header = [];
        for (let i = 0; i < 7; i++) {
          header.push(Dates.Day(i + (i == 6 ? -6 : 1)));
        }
        return header;
      },
      DateString(date) {
        return Tool.DateString(date);
      },
      IsToday(date) {
        return this.DateString(new Date()) == this.DateString(date);
      },
      CalendarBody() {
        if (this.month == null 
        && this.year == null) {
          return;
        }
        let first_date = new Date();
        let last_date  = new Date();
        const dates = [], weeks = [];

        first_date = new Date(this.year, this.month, 1);
        last_date = new Date(this.year, this.month + 1, 0);
        
        for (let i = first_date.getDate(); i <= last_date.getDate(); i++) {
          const tmp_date = new Date(this.year, this.month, i);
          /*
          tmp_date.setFullYear(this.year);
          tmp_date.setMonth(this.month);
          tmp_date.setDate(i);
          tmp_date.setHours(1, 0, 0);
          */
          dates.push(tmp_date);
        }
        
        const prev_month_days = (dates[0].getDay() || 7) - 1;

        for (let i = 0; i < prev_month_days; i++) {
          const date = new Date(dates[0]);
          date.setDate(date.getDate() - 1);
          dates.unshift(date);
          //dates.unshift('');
        } 

        for (let i = 0; i < dates.length; i += 7) {
          weeks.push(dates.slice(i, i + 7));
        }

        const last_week = weeks.length - 1;
        const next_month_days = 7 - weeks[last_week].length;

        for (let i = 0; i < next_month_days; i++) {
          const date = new Date(dates[dates.length - 1]);
          date.setDate(date.getDate() + (i + 1));
          weeks[last_week].push(date);
          //weeks[last_week].push('');
        }

        return weeks.flat();
      },
      SetDate(event, date) {
        let classList = event.target.closest('li').classList;
        if (classList.contains('inactive') || classList.contains('disabled')) return;
        let el = event.target.closest('.calendar__body').querySelector('.active');
        if (el) el.classList.remove('active');
        let change = new Event('change');
        if (date?.getDate() == this.SelectedDate?.getDate()) {
          this.ResetValue(event.target);
          this.$emit('date', null);
          return;
        }
        this.SelectedDate = date;
        //event.target.closest('a').classList.add('active');
        this.$emit('date', this.SelectedDate.setHours(0, 0, 0));
        event.target.closest('.date-select-label').querySelector('.fake-date').dispatchEvent(change);
      },
      ShowDropdown(event) {
        if (event.type == 'focus') {
          let date = new Date();
          if (this.DisabledBefore) {
            date = new Date(this.DisabledBefore);
          }
          if (this.SelectedDate) {
            date = this.SelectedDate;
          }
          this.year = date.getFullYear();
          this.month = date.getMonth();
        }
        if (!event.target.nextSibling.classList.contains('show')) {
          event.target.nextSibling.classList.add('show');
          this.dropdown = event.target.nextSibling;
          this.ClickHandler(true);
        }
      },
      HideDropdown(event) {
        if ('key' in event) {
          this.dropdown.classList.remove('show');
          this.ClickHandler(false);
          return;
        }
        let path = event.path || (event.composedPath && event.composedPath());
        if (!path.includes(this.dropdown) && event.target != this.dropdown.previousSibling) {
          this.dropdown.classList.remove('show');
          this.ClickHandler(false);
        }
      },
      ClickHandler(state) {
        if (this.binding == null) {
          window.getClicks = event => this.HideDropdown(event);
          this.binding = window.getClicks.bind(null);
        }
        if (!state) {
          window.removeEventListener('click', this.binding);
          this.IsBound = state;
        } else {
          window.addEventListener('click', this.binding);
          this.IsBound = state;
        }
      },
      PreventInput(event) {
        event.preventDefault();
      },
      CheckAvailability(date) {
        if (typeof date == 'object') {
          if (date.getMonth() != this.month) {
            return 'inactive surrounding';
          }
          if (this.DisabledBefore !== null) {
            let tmp_date = date.setHours(23, 59, 59);
            if (this.DisabledBefore > tmp_date) {
              return 'inactive';
            }
          }
        } else {
          return 'inactive';
        }
      },
      DateController(action) {
        if (action) {
          if (this.month == 11) {
            this.year++;
            this.month = 0;
          } else {
            this.month++;
          }
        } else {
          if (this.month == 0) {
            this.year--;
            this.month = 11;
          } else {
            this.month--;
          }
        }
      },
      ResetValue(element) {
        this.SelectedDate = null;
        if (!element) return;
        const change = new Event('change');
        const label = element.closest('.date-select-label');
        label.querySelector('.fake-date').dispatchEvent(change);
        label.querySelector('.active')?.classList.remove('active');
      },
      Changed(event) {
        setTimeout(() => this.ValidateInput(event));
      }
    }
  };
</script>

<style scoped>
</style>
