import { Language } from '@/helpers/Language';

export const Dates = {
  lang: Language.GetLanguage(),
  Day(day) {
    switch (this.lang) {
      case 'en':
        switch (day.toString()) {
          case '0':
            day = 'Sun';
            break;
          case '1':
            day = 'Mon';
            break;
          case '2':
            day = 'Tue';
            break;
          case '3':
            day = 'Wed';
            break;
          case '4':
            day = 'Thu';
            break;
          case '5':
            day = 'Fri';
            break;
          case '6':
            day = 'Sat';
            break;
          default:
            break;
        }
        break;
    
      default:
        break;
    }
    return day;
  },
  DayLetter(day) {
    switch (this.lang) {
      case 'en':
        switch (day.toString()) {
          case '0':
            day = 'Su';
            break;
          case '1':
            day = 'Mo';
            break;
          case '2':
            day = 'Tu';
            break;
          case '3':
            day = 'We';
            break;
          case '4':
            day = 'Th';
            break;
          case '5':
            day = 'Fr';
            break;
          case '6':
            day = 'Sa';
            break;
          default:
            break;
        }
        break;
    
      default:
        break;
    }
    return day;
  },
  Month(month) {       
    switch (this.lang) {
      case 'en':
        switch (month) {
          case 0:
            month = 'Jan.';
            break;
          case 1:
            month = 'Feb.';
            break;
          case 2:
            month = 'Mar.';
            break;
          case 3:
            month = 'Apr.';
            break;
          case 4:
            month = 'May';
            break;
          case 5:
            month = 'June';
            break;
          case 6:
            month = 'July';
            break;
          case 7:
            month = 'Aug.';
            break;
          case 8:
            month = 'Sep.';
            break;
          case 9:
            month = 'Oct.';
            break;
          case 10:
            month = 'Nov.';
            break;
          case 11:
            month = 'Dec.';
            break;
        }
        break;
    }
    return month;
  },
  GetTextMonthWithoutYear(date) {
    return date.getDate() + '. ' + this.Month(date.getMonth());
  },
  GetTextDate(date) {
    return date.getDate() + '. ' + this.Month(date.getMonth()) + ' ' + date.getFullYear();
  },
  GetTextMonth(month) {
    return this.Month(month);
  },
  FormatDoubleDigits(num) {
    var s = num + "";
    while (s.length < 2) s = '0' + s;
    return s;
  }
};
